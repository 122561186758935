<template>
  <div>
    <v-navigation-drawer v-model="drawer" fixed app temporary right width="400">
      <v-progress-linear indeterminate color="primary" v-if="loading" height="2"></v-progress-linear>
      <v-toolbar flat>
        <v-toolbar-title class="grey--text">
          {{ title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="drawer = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider class="my-1"></v-divider>

      <slot>
        <v-skeleton-loader
          v-bind="attrs"
          type="list-item-three-line,list-item-two-line,list-item-three-line,list-item-three-line,list-item-two-line,list-item-three-line"
        ></v-skeleton-loader>
        <!-- <v-overlay absolute :value="loading"><v-progress-circular indeterminate color="primary" v-if="loading" :width="3" size="25"></v-progress-circular></v-overlay> -->
      </slot>
    </v-navigation-drawer>
  </div>
</template>
<script>
export default {
  name: 'RightBar',
  props: {
    title: {
      type: String,
      default: 'Loading...',
    },
    loading: {
      type: Boolean,
      default: null,
    },
    attrs: {
      class: 'mb-1',
      boilerplate: true,
      elevation: 2,
    },
  },
  data: () => ({
    drawer: false,
  }),
  methods: {
    show() {
      this.drawer = true
    },
    hide() {
      this.drawer = false
    },
  },
}
</script>