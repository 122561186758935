<template>
  <RightBar ref="myRightbar" :title="title" :loading="loading">
    <template v-if="(typeof data.contract !== 'undefined')">
      <v-card flat>
        <v-list two-line subheader dense>
          <v-subheader>General</v-subheader>
          <v-list-item dense style="min-height: 50px">
            <v-list-item-content>
              <v-list-item-title class="lbl">Contract No.</v-list-item-title>
              <v-list-item-subtitle class="vl">{{ data.contract.contract_no_ ? data.contract.contract_no_ : '-' }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item dense style="min-height: 50px">
            <v-list-item-content>
              <v-list-item-title class="lbl">Contract Date</v-list-item-title>
              <v-list-item-subtitle class="vl">{{ appDateFormat(data.contract.contract_date) }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>

          <v-subheader>Customer</v-subheader>
          <v-list-item dense style="min-height: 50px">
            <v-list-item-content>
              <v-list-item-title class="lbl">Name</v-list-item-title>
              <v-list-item-subtitle class="vl">{{ data.customer.name }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item dense style="min-height: 50px">
            <v-list-item-content>
              <v-list-item-title class="lbl">Address</v-list-item-title>
              <v-list-item-subtitle class="vl">{{ data.customer.address }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item dense style="min-height: 50px">
            <v-list-item-content>
              <v-list-item-title class="lbl">Address 2</v-list-item-title>
              <v-list-item-subtitle class="vl">{{ data.customer.address_2 }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>

          <v-subheader>Campaign</v-subheader>
          <v-list-item dense style="min-height: 50px">
            <v-list-item-content>
              <v-list-item-title class="lbl">Project</v-list-item-title>
              <v-list-item-subtitle class="vl"
                ><router-link :to="'/campaign/detail/' + data.campaign.no_">{{
                  data.campaign.description
                }}</router-link></v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item dense style="min-height: 50px">
            <v-list-item-content>
              <v-list-item-title class="lbl">Location</v-list-item-title>
              <v-list-item-subtitle class="vl">{{ data.campaign.city }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

      </v-card>
    </template>
  </RightBar>
</template>

<script>
import RightBar from '@/components/RightBar.vue'

export default {
  components: {
    RightBar,
  },

  data: () => ({
    title: '# Contract Info',
    subtitle: '',
    data: {},
    loading: false,
  }),

  methods: {
    getData(contract_no_) {
      this.data = {}
      this.loading = true
      this.$refs.myRightbar.show()
      var params = {
        contract_no_: contract_no_,
      }
      this.$axios
        .get('orders/contract/info', { params: params, cacheConfig: true, })
        .then(res => {
          this.loading = false
          if (res.status) {
            var resData = res.data
            if (resData.status == 'success') {
              this.data = resData.data
            } else {
              this.showAlert(resData.status, resData.message);
              this.$refs.myRightbar.hide()
            }
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          // console.log(error)
          this.$awn.alert('Internal Server Error')
          this.loading = false
        })
    },
    show() {
      this.$refs.myRightbar.show()
    },
    hide() {
      this.$refs.myRightbar.hide()
    },
  },
}
</script>

<style>
.lbl {
  color: #bdbdbd !important;
}
.vl {
  color: #616161 !important;
}
</style>