<template>
  <div>
    <v-card :loading="loadingCard" elevation="2" outlined>
      <v-skeleton-loader v-bind="{boilerplate: true, elevation: 2,}" v-if="loadingCard" type="tab, table"></v-skeleton-loader>

      <div v-if="!loadingCard">
        <!-- <v-card-title>Invoice</v-card-title> -->
        <v-toolbar>
          <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
          <v-toolbar-title>Invoices</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="getData">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-toolbar>

        <v-tabs v-model="tab" class="tab-index">
          <v-tab v-for="item in items" :key="item.name">
            <v-icon class="mr-2">{{ item.icon }}</v-icon>
            {{ item.name }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(item, i) in items" :key="i" class="mt-2">
            <v-card-title>
              <v-spacer></v-spacer>
              <v-text-field v-model="item.search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
            </v-card-title>
            <v-data-table :loading="showLoadingComponent" loading-text="Loading... Please wait" :headers="item.columns" :items="item.data" :search="item.search" dense>
              <template v-slot:[`item.campaign.description`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="d-inline-block text-truncate" style="max-width: 200px">{{ item.campaign.description }}</span>
                  </template>
                  <span>{{ item.campaign.description }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.no_`]="{ item }">
                <!-- <a href="javascript:;" @click="$refs.myInvoiceDetail.getData(item.no_)">{{ item.no_ }}</a> -->

                <router-link :to="{ name: 'InvoiceDetail', query: { no_: item.no_ } }">{{ item.no_ }}</router-link>
              </template>
              <template v-slot:[`item.contract_no_`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <a href="javascript:;" @click="$refs.refContractInfo.getData(item.contract_no_)" v-bind="attrs" v-on="on" class="d-inline-block text-truncate" style="max-width: 150px">{{ item.contract_no_ }}</a>
                  </template>
                  <span>{{ item.contract_no_ }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.po_no_`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <a href="javascript:;" @click="$refs.refPOInfo.getData(item.po_no_)" v-bind="attrs" v-on="on" class="d-inline-block text-truncate" style="max-width: 150px">{{ item.po_no_ }}</a>
                  </template>
                  <span>{{ item.po_no_ }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.due_date`]="{ item }">{{ appDateFormat(item.due_date) }}</template>
              <template v-slot:[`item.amount`]="{ item }">{{ numberFormat(item.amount, 2) }}</template>
              <template v-slot:[`item.is_paid`]="{ item }">
                <v-icon v-if="item.is_paid">mdi-thumb-up</v-icon>
                <v-icon v-if="!item.is_paid">mdi-thumb-down</v-icon>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-card>
    <InvoiceDetail ref="myInvoiceDetail"></InvoiceDetail>
    <ContractInfo ref="refContractInfo"></ContractInfo>
    <POInfo ref="refPOInfo"></POInfo>
  </div>
</template>

<script>
import { mdiDatabaseSearch, mdiArrowLeftCircle, mdiHuman, mdiSack, mdiShopping, mdiTable, mdiClock } from '@mdi/js'

import InvoiceDetail from '@/components/invoice/InvoiceDetail.vue'
import ContractInfo from '@/components/rigthbar/ContractInfo.vue'
import POInfo from '@/components/rigthbar/POInfo.vue'

export default {
  components: {
    InvoiceDetail,
    ContractInfo,
    POInfo,
  },
  data: () => ({
    loadingCard: false,
    loadingStatistic: false,
    showLoadingComponent: null,
    dialog: null,
    icons: {
      mdiArrowLeftCircle,
      mdiDatabaseSearch,
    },
    tab: null,
    items: [
      {
        name: 'All',
        icon: mdiTable,
        search: null,
        columns: [
          // { text: 'No.', value: 'i' },
          { text: 'Paid', value: 'is_paid' },
          {
            text: 'Campaign',
            align: 'start',
            value: 'campaign.description',
          },
          { text: 'Invoice No.', value: 'no_' },
          { text: 'Due Date', value: 'due_date' },
          { text: 'Contract No.', value: 'contract_no_' },
          { text: 'PO No.', value: 'po_no_' },
          { text: 'Amount Excl. VAT', value: 'amount', align: 'end' },
        ],
        data: [],
      },
      {
        name: 'Sales',
        icon: mdiShopping,
        search: null,
        columns: [
          // { text: 'No.', value: 'i' },
          { text: 'Paid', value: 'is_paid' },
          {
            text: 'Campaign',
            align: 'start',
            value: 'campaign.description',
          },
          { text: 'Invoice No.', value: 'no_' },
          { text: 'Due Date', value: 'due_date' },
          { text: 'Contract No.', value: 'contract_no_' },
          { text: 'PO No.', value: 'po_no_' },
          { text: 'Amount Excl. VAT', value: 'amount', align: 'end' },
        ],
        data: [],
      },
      {
        name: 'Rental',
        icon: mdiSack,
        search: null,
        columns: [
          // { text: 'No.', value: 'i' },
          { text: 'Paid', value: 'is_paid' },
          {
            text: 'Campaign',
            align: 'start',
            value: 'campaign.description',
          },
          { text: 'Invoice No.', value: 'no_' },
          { text: 'Due Date', value: 'due_date' },
          { text: 'Contract No.', value: 'contract_no_' },
          { text: 'PO No.', value: 'po_no_' },
          { text: 'Amount Excl. VAT', value: 'amount', align: 'end' },
        ],
        data: [],
      },
      {
        name: 'Service',
        icon: mdiHuman,
        search: null,
        columns: [
          // { text: 'No.', value: 'i' },
          { text: 'Paid', value: 'is_paid' },
          {
            text: 'Campaign',
            align: 'start',
            value: 'campaign.description',
          },
          { text: 'Invoice No.', value: 'no_' },
          { text: 'Due Date', value: 'due_date' },
          { text: 'Contract No.', value: 'contract_no_' },
          { text: 'PO No.', value: 'po_no_' },
          { text: 'Amount Excl. VAT', value: 'amount', align: 'end' },
        ],
        data: [],
      },
      {
        name: 'Invoice Due',
        icon: mdiClock,
        search: null,
        columns: [
          // { text: 'No.', value: 'i' },
          { text: 'Paid', value: 'is_paid' },
          {
            text: 'Campaign',
            align: 'start',
            value: 'campaign.description',
          },
          { text: 'Invoice No.', value: 'no_' },
          { text: 'Due Date', value: 'due_date' },
          { text: 'Contract No.', value: 'contract_no_' },
          { text: 'PO No.', value: 'po_no_' },
          { text: 'Amount Excl. VAT', value: 'amount', align: 'end' },
        ],
        data: [],
      },
    ],
    search: '',
  }),

  methods: {
    getData() {
      this.loadingCard = true

      this.$axios
        .get('invoice/data', {
          cacheConfig: true,
        })
        .then(res => {
          if (res.status) {
            var resData = res.data
            if (resData.status == 'success') {
              var _this = this
              _this.items[0].data = []
              _this.items[1].data = []
              _this.items[2].data = []
              _this.items[3].data = []
              _this.items[4].data = []

              resData.data.invoice.forEach(function (item) {
                let _item = item

                _this.items[0].data.push(_item)
                if (item.order_type == 'Sales') {
                  _this.items[1].data.push(_item)
                } else if (item.order_type == 'Rental') {
                  _this.items[2].data.push(_item)
                } else if (item.order_type == 'Service') {
                  _this.items[3].data.push(_item)
                }

                if (item.is_paid == false) {
                  _this.items[4].data.push(_item)
                }
              })
            }
          }
          this.loadingCard = false
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.$awn.alert('Internal Server Error')
          this.loadingCard = false
        })
    },
  },
}
</script>