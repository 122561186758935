<template>
  <div>
    <v-row>
      <v-col cols="12" md="6" lg="8" class="d-flex align-stretch" fluid>
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card dark height="100%" elevation="2" class="d-flex align-stretch" tile>
              <v-img :src="require('@/assets/images/bg1.jpg')">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                  </v-row>
                </template>
                <v-card-text class="py-2 text-center">
                  <v-icon large color="primary">mdi-cash</v-icon>
                  <h1>Invoices</h1>
                  <p>This page show all invoices.</p>
                </v-card-text>
              </v-img>
              <v-fade-transition>
                <v-overlay v-if="hover" absolute color="#036358">
                  <!-- <a href="#delivery_progress" color="white">See more</a> -->
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </template>
        </v-hover>
      </v-col>
      <v-col cols="6" md="2" lg="2">
        <StatisticsCardVertical class="fill-height" :loading="loadingStatistic" change color="error" icon="mdi-clock" :statistics="numberFormat(0).toString()" stat-title="Invoice Due" subtitle="Number of Invoice Due"></StatisticsCardVertical>
      </v-col>
      <v-col cols="6" md="2" lg="2">
        <StatisticsCardVertical class="fill-height" :loading="loadingStatistic" change color="success" icon="mdi-cash" :statistics="numberFormat(0).toString()" stat-title="Invoices" subtitle="Number of invoice"></StatisticsCardVertical>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <invoice-due></invoice-due>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <your-invoices ref="refYourInvoices"></your-invoices>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import StatisticsCardVertical from '@/components/statistics-card/StatisticsCardVertical.vue'

import YourInvoices from '@/components/invoice/YourInvoices.vue'
import InvoiceDue from '@/components/invoice/InvoiceDue.vue'

export default {
  metaInfo: {
    title: 'Invoices',
  },
  components: {
    StatisticsCardVertical,
    YourInvoices,
    InvoiceDue,
  },
  data: () => ({
    loadingStatistic: false,
  }),
  methods: {},
  mounted() {
    this.$store.state.showBackButton = false
    this.setTitle('Invoices')
    this.$refs.refYourInvoices.getData()
  },
}
</script>