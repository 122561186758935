var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"loading":_vm.loadingCard,"elevation":"2","outlined":""}},[(_vm.loadingCard)?_c('v-skeleton-loader',_vm._b({attrs:{"type":"tab, table"}},'v-skeleton-loader',{boilerplate: true, elevation: 2,},false)):_vm._e(),(!_vm.loadingCard)?_c('div',[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Invoices")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.getData}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-tabs',{staticClass:"tab-index",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c('v-tab',{key:item.name},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.name)+" ")],1)}),1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item,i){return _c('v-tab-item',{key:i,staticClass:"mt-2"},[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(item.search),callback:function ($$v) {_vm.$set(item, "search", $$v)},expression:"item.search"}})],1),_c('v-data-table',{attrs:{"loading":_vm.showLoadingComponent,"loading-text":"Loading... Please wait","headers":item.columns,"items":item.data,"search":item.search,"dense":""},scopedSlots:_vm._u([{key:"item.campaign.description",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"200px"}},'span',attrs,false),on),[_vm._v(_vm._s(item.campaign.description))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.campaign.description))])])]}},{key:"item.no_",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'InvoiceDetail', query: { no_: item.no_ } }}},[_vm._v(_vm._s(item.no_))])]}},{key:"item.contract_no_",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"150px"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$refs.refContractInfo.getData(item.contract_no_)}}},'a',attrs,false),on),[_vm._v(_vm._s(item.contract_no_))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.contract_no_))])])]}},{key:"item.po_no_",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"150px"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$refs.refPOInfo.getData(item.po_no_)}}},'a',attrs,false),on),[_vm._v(_vm._s(item.po_no_))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.po_no_))])])]}},{key:"item.due_date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.appDateFormat(item.due_date)))]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.numberFormat(item.amount, 2)))]}},{key:"item.is_paid",fn:function(ref){
var item = ref.item;
return [(item.is_paid)?_c('v-icon',[_vm._v("mdi-thumb-up")]):_vm._e(),(!item.is_paid)?_c('v-icon',[_vm._v("mdi-thumb-down")]):_vm._e()]}}],null,true)})],1)}),1)],1):_vm._e()],1),_c('InvoiceDetail',{ref:"myInvoiceDetail"}),_c('ContractInfo',{ref:"refContractInfo"}),_c('POInfo',{ref:"refPOInfo"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }